<script setup lang="ts">


import {onMounted} from "vue";
import {useHomeStore} from "@/stores/home.store";

const homeStore = useHomeStore();
import {useLayoutConfigurator} from "@/layout/header/layoutConfigurator";

if(import.meta.env.MODE == "development" || import.meta.env.MODE == "test" ){
  console.log("API URL: " + import.meta.env.VITE_API_URL + "\n" + "APP URL: " + import.meta.env.VITE_APP_BASE_URL );
}

onMounted(()=>{

})
</script>

<template>
  <div v-if="homeStore.isShowPaceLoading" class=" bg-amber-300 fixed w-full flex justify-center" style="z-index: 998">
    <ProgressBar mode="indeterminate" style="height: 7px;" class="w-full"></ProgressBar>
  </div>
  <Toast/>
  <Toast position="bottom-right" group="br" />

  <ConfirmPopup></ConfirmPopup>
  <ConfirmDialog group="cd"></ConfirmDialog>
  <DynamicDialog />


  <router-view />
</template>

<style scoped></style>
