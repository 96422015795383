import axios from "axios";
import type {DefaultResponse} from "@/type/request.type";



export const useRequestHelper = () => {


    const API_URL = import.meta.env.VITE_API_URL;
    const APP_BASE_URL = import.meta.env.VITE_APP_BASE_URL;

    function getUrl(url: string) {
        return API_URL + url;
    }

    function getBaseAppUrl(url: string) {
        return APP_BASE_URL + url;
    }


    function getDefaultHeaders(withToken : boolean = true) {

        let token = "";
        let user : string | null = "";

        if (withToken) {

            user = localStorage.getItem('user') !== null ? localStorage.getItem('user') : "";
            token = (user ? user : '');
        }

        return {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token,
        };
    }


    function getErrorResponse(error: any): DefaultResponse<string> {

        let message = "";
        let errorMsg = "ERRO DESCONHECIDO";


        //Verifica se a API retornou erro
        if (error.response) {

            //Verifica se é erro de Autenticacao
            if (error.response.status == 401) {
                errorMsg = "NÃO AUTENTICADO"

                //Verifica se é erro de Permissão
            } else if (error.response.status == 403) {
                errorMsg = "NÃO AUTORIZADO"
            } else if (error.response.status == 400) {

                if (error.response.data.data) {
                    errorMsg = verificaArrayMessage(error.response.data.data);
                }

                if(error.response.data.message){
                    message = error.response.data.message.toString().toUpperCase();
                }

            } else if (error.response.data == "") {
                errorMsg = error.response.status + " - " + error.response.statusText.toString().toUpperCase();
            } else if (error.response.status == 500) {
                errorMsg = "SERVER ERROR \n" + error.response.data;
            } else {
                //Verifica se a API retornou erros
                if (error.response.data.data) {
                    errorMsg = error.response.data.data;
                }

            }
        }

        return {
            success: false,
            data: errorMsg.toString(),
            message: message
        };

    }

    function verificaArrayMessage(data: any): string {

        let mensagemRetorno = "";

        if (Array.isArray(data)) {

            //Itera o array
            data.forEach(e => {
                if (e.key && e.message) {
                    mensagemRetorno += (mensagemRetorno !== "" ? "\n" : "") +
                        e.key.toString().toUpperCase() + ' - ' + e.message.toString().toUpperCase();
                } else {
                    mensagemRetorno += (mensagemRetorno !== "" ? "\n" : "") +
                        e.toString();
                }

            })

        } else {
            //é um objeto
            //Procura pela chave KEY e MESSAGE e monta uma string de retorno
            if (data.key && data.message) {
                mensagemRetorno = data.key.toString().toUpperCase() + ' - ' + data.message.toString().toUpperCase();
            } else {
                mensagemRetorno = data.toString();
            }
        }

        return mensagemRetorno;
    }


    function sendRequestGet<Type>(url: string,withToken:boolean = true): Promise<DefaultResponse<Type>> {

        try {
            return axios
                .get(getUrl(url),
                    {
                        headers: getDefaultHeaders(withToken),
                    }
                )
                .then(response => {
                    //o método THEN é executado quando a requisição ocorre sem ERROS

                    const responseSuccess: DefaultResponse<Type> = {
                        data: response.data.data,
                        success: response.data.success,
                        message: response.data.message
                    }

                    //Retorna sucesso na Promise
                    return Promise.resolve(responseSuccess);

                })
                .catch(error => {
                    //o método CATCH é executado quando ocorre algum erro na requisição.
                    //Seja erro interno de servidor, de conexão ou mesmo um retorno de erro da propria API (codigo 4...)

                    //Retorna um erro na Promise
                    return Promise.reject(getErrorResponse(error));

                });


        } catch (exp) {

            const excptRetorno: DefaultResponse<string> = {
                success: false,
                data: "REQUEST ERROR",
                message: ""

            }
            console.log("REQUEST ERROR", exp);
            return Promise.reject(excptRetorno);
        }


    }

    function sendRequestPost<Type>(url: string, data: any,withToken:boolean = true): Promise<DefaultResponse<Type>> {

        try {
            return axios
                .post(getUrl(url),
                    data,
                    {
                        headers: getDefaultHeaders(withToken),
                    }
                )
                .then(response => {
                    //o método THEN é executado quando a requisição ocorre sem ERROS

                    const responseSuccess: DefaultResponse<Type> = {
                        data: response.data.data,
                        success: response.data.success,
                        message: response.data.message
                    }

                    //Retorna sucesso na Promise
                    return Promise.resolve(responseSuccess);

                })
                .catch(error => {
                    //o método CATCH é executado quando ocorre algum erro na requisição.
                    //Seja erro interno de servidor, de conexão ou mesmo um retorno de erro da propria API (codigo 4...)

                    //Retorna um erro na Promise
                    return Promise.reject(getErrorResponse(error));

                });


        } catch (exp) {

            const excptRetorno: DefaultResponse<string> = {
                success: false,
                data: "REQUEST ERROR",
                message: ""

            }
            console.log("REQUEST ERROR", exp);
            return Promise.reject(excptRetorno);
        }


    }


    function sendPost(url:string,data:any,config:any,withToken:boolean = true){

        config["headers"] = getDefaultHeaders(withToken)
        return  axios.post(url,data,config);

    }



    return {API_URL,  sendRequestGet, sendRequestPost,sendPost}
}