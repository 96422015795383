<script setup lang="ts">

import {ref, type Ref} from "vue";
import {useAuthStore} from "@/stores/auth.store";
import UsuarioService from "@/services/usuario.service";
import {useToastHelper} from "@/helpers/toast.helper";
import {EStatusUsuario} from "@/type/usuario.type";


const {showToastSuccess, showRequestError} = useToastHelper();


const authStore = useAuthStore();

const exibirModal = ref(false);

const isCodigoEnviado = ref(false);
const isLoadingEnvioCodigo = ref(false);
const isLoadingConfirmacaoEmail = ref(false);

const codigoConfirmacao = ref("");
const erroConfirmacao: Ref<string | null> = ref(null);

const onEnviarCodigo = () => {

  isLoadingEnvioCodigo.value = true;


  UsuarioService.solicitarCodigoConfirmacao()
      .then(() => {
        isCodigoEnviado.value = true;
      })
      .catch((error) => showRequestError(error))
      .finally(() => isLoadingEnvioCodigo.value = false);


}

const onConfirmarEmail = () => {

  if (codigoConfirmacao.value.length < 4) return false;

  isLoadingConfirmacaoEmail.value = true;
  erroConfirmacao.value = null;
  UsuarioService
      .confirmarEmail({codigoConfirmacao: codigoConfirmacao.value})
      .then(() => {
        showToastSuccess("Email Confirmado", "");
        authStore.setUserStatus(EStatusUsuario.Confirmado);
        exibirModal.value = false;

      })
      .catch((error) => {
        erroConfirmacao.value = error.message != ""?error.message : error.data;
      })
      .finally(() => isLoadingConfirmacaoEmail.value = false);

}

const onAfterHideModel = () => {
  exibirModal.value = false;
  isCodigoEnviado.value = false;
  erroConfirmacao.value = null;
  codigoConfirmacao.value = '';
}

</script>

<template>

  <Message class="alert-message-confirmacao-usuario" severity="warn" closable>
    <strong>E-MAIL NÃO CONFIRMADO</strong>
    <p>&nbsp;</p>
    <p>
      <Button link label="Clique aqui" @click="exibirModal=true"></Button>
      para confirmar o e-mail
    </p>
  </Message>


  <Dialog v-model:visible="exibirModal" modal header="Confirmar Email" :style="{ width: '35rem' }"
          @afterHide="onAfterHideModel">

    <div v-if="!isCodigoEnviado">

      <div class="flex items-center gap-4 mb-4">
      <span>
      Será enviado um código de confirmação para o email <strong>{{ authStore.userEmail }}</strong>
      </span>

      </div>

      <div class="flex gap-3 justify-center  ">
        <Button type="button" label="Enviar código" severity="info" @click="onEnviarCodigo" :loading="isLoadingEnvioCodigo"></Button>
        <Button label="Clique aqui se já recebeu um código"  @click="isCodigoEnviado = true"  :loading="isLoadingEnvioCodigo" ></Button>
      </div>
    </div>

    <div v-if="isCodigoEnviado">

      <div class="flex flex-col items-center gap-4 mb-4">
        <span>
          Informe o código
        </span>

        <div class="flex flex-col justify-items-center">
          <InputOtp class="justify-center" autofocus :length="5" v-model="codigoConfirmacao"  integerOnly :disabled="isLoadingConfirmacaoEmail" @keydown.enter="onConfirmarEmail"/>
          <Message v-if="erroConfirmacao" severity="error" class="mt-3">{{ erroConfirmacao }}</Message>
        </div>

      </div>

      <div class="flex justify-center gap-2 ">
        <Button type="button" severity="secondary" label="Voltar" @click="isCodigoEnviado = false" :loading="isLoadingConfirmacaoEmail"></Button>
        <Button type="button" label="Confirmar" @click="onConfirmarEmail" :loading="isLoadingConfirmacaoEmail"></Button>
      </div>
    </div>


  </Dialog>


</template>

<style lang="scss" scoped>

.alert-message-confirmacao-usuario {

  position: fixed;
  top: 8rem;
  left: 1rem;
  width: calc(100% - 2rem);
  z-index: 1;
}
</style>