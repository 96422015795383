import {computed, reactive, readonly} from 'vue';

const layoutConfig = reactive({
    preset: 'Aura',
    primary: 'yellow',
    surface: 'zinc',
    darkTheme: true,
    menuMode: 'overlay'
});

const layoutState = reactive({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    activeMenuItem: null
});

export function useLayout() {
    const setPrimary = (value: any) => {
        layoutConfig.primary = value;

    };

    const setSurface = (value: any) => {
        layoutConfig.surface = value;
    };

    const setPreset = (value: any) => {
        layoutConfig.preset = value;
    };

    const setActiveMenuItem = (item: any) => {
        layoutState.activeMenuItem = item.value || item;
    };

    const setMenuMode = (mode: any) => {
        layoutConfig.menuMode = mode;
    };

    const toggleDarkMode = () => {
        if (!(document as any).startViewTransition) {
            executeDarkModeToggle();

            return;
        }

        (document as any).startViewTransition(() => executeDarkModeToggle());
    };

    const executeDarkModeToggle = () => {
        layoutConfig.darkTheme = !layoutConfig.darkTheme;
        document.documentElement.classList.toggle('app-dark');
    };

    const setDarkLightMode = (darkMode: boolean) => {
        if (!(document as any).startViewTransition) {
            if (darkMode) {
                setDarkMode()
            } else {
                setLightMode()
            }

            return;
        }

        (document as any).startViewTransition(() => {
            if (darkMode) {
                setDarkMode()
            } else {
                setLightMode()
            }
        });
    };

    const setDarkMode = () => {

        layoutConfig.darkTheme = true;
        document.documentElement.classList.add("app-dark")
    }

    const setLightMode = () => {
        layoutConfig.darkTheme = false;
        document.documentElement.classList.remove('app-dark');
    }

    const onMenuToggle = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
        }

        if (window.innerWidth > 991) {
            layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
        } else {
            layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
        }
    };

    const resetMenu = () => {
        layoutState.overlayMenuActive = false;
        layoutState.staticMenuMobileActive = false;
        layoutState.menuHoverActive = false;
    };

    const isSidebarActive = computed(() => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive);

    const isDarkTheme = computed(() => layoutConfig.darkTheme);

    const getPrimary = computed(() => layoutConfig.primary);

    const getSurface = computed(() => layoutConfig.surface);

    return {
        layoutConfig: readonly(layoutConfig),
        layoutState: readonly(layoutState),
        onMenuToggle,
        isSidebarActive,
        isDarkTheme,
        getPrimary,
        getSurface,
        setActiveMenuItem,
        toggleDarkMode,
        setPrimary,
        setSurface,
        setPreset,
        resetMenu,
        setMenuMode,
        setDarkLightMode
    };
}
