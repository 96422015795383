import {useRequestHelper} from "@/helpers/request.helper";
import type {UsuarioClienteVm,UsuarioInternoVm, UsuarioInterno} from "@/type/usuario.type";
import type {
    AlterarEmailUsuarioCommand,
    AlterarInfoUsuarioCommand,
    AlterarSenhaUsuarioCommand, AlterarUsuarioInternoCommand,
    CadastrarUsuarioInternoCommand,
    CadastrarUsuarioClienteCommand,
    AlterarUsuarioClienteCommand,
    ConfirmarEmailCommand,
    RecuperarSenhaUsaurioCommand,
    SolicitarRecuperacaoUsuarioCommand
} from "@/commands/usuario.commands";
import type {
    ConsultarUsuarioClienteQuery,
    ConsultarUsuarioInternoQuery,
    ListarUsuarioClienteQuery,
    ListarUsuarioInternoQuery,
    RemoverVinculoUsuarioClienteEmpresaQuery
} from "@/queries/usuario.query";

const requestHelper = useRequestHelper();


class UsuarioService {

    consultar() {
        return requestHelper.sendRequestGet<UsuarioInterno>('usuario/info');
    }

    alterarInfoUsuario(command: AlterarInfoUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/informacoes', command);
    }

    alterarSenha(command: AlterarSenhaUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/senha', command);
    }


    alterarEmail(command: AlterarEmailUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/alterar/email', command);
    }


    solicitarCodigoConfirmacao() {
        return requestHelper.sendRequestPost<object>('usuario/solicitar/confirmacao', {});
    }

    confirmarEmail(command: ConfirmarEmailCommand) {
        return requestHelper.sendRequestPost<object>('usuario/confirmar', command);
    }

    solicitarRecuperacaoSenha(command: SolicitarRecuperacaoUsuarioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/solicitar/recuperacao', command, false);

    }

    recuperarSenha(command: RecuperarSenhaUsaurioCommand) {
        return requestHelper.sendRequestPost<object>('usuario/recuperar-senha', command, false);
    }

    cadastrarUsuarioInterno(command: CadastrarUsuarioInternoCommand){
        return requestHelper.sendRequestPost<object>('usuario/interno/cadastrar', command);
    }

    alterarUsuarioInterno(command: AlterarUsuarioInternoCommand){
        return requestHelper.sendRequestPost<object>('usuario/interno/alterar', command);
    }

    listarUsuarioInterno(query: ListarUsuarioInternoQuery){
        return requestHelper.sendRequestPost<UsuarioInternoVm[]>("usuario/interno/listar",query);
    }

    consultarUsuarioInterno(query: ConsultarUsuarioInternoQuery){
        return requestHelper.sendRequestPost<UsuarioInternoVm>("usuario/interno/consultar",query);
    }

    cadastrarUsuarioCliente(command: CadastrarUsuarioClienteCommand){
        return requestHelper.sendRequestPost<object>('usuario/cliente/cadastrar', command);
    }

    alterarUsuarioCliente(command: AlterarUsuarioClienteCommand){
        return requestHelper.sendRequestPost<object>('usuario/cliente/alterar', command);
    }

    listarUsuarioCliente(query: ListarUsuarioClienteQuery){
        return requestHelper.sendRequestPost<UsuarioClienteVm[]>("usuario/cliente/listar",query);

    }

    consultarUsuarioCliente(query: ConsultarUsuarioClienteQuery){
        return requestHelper.sendRequestPost<UsuarioClienteVm>("usuario/cliente/consultar",query);
    }

    removerVinculoUsuarioClienteEmpresa(query: RemoverVinculoUsuarioClienteEmpresaQuery){
        return requestHelper.sendRequestPost<object>("usuario/cliente/remover-vinculo",query);
    }
}

export default new UsuarioService();