import {createRouter, createWebHashHistory} from 'vue-router';

import {useAuthStore} from "@/stores/auth.store";
import {EPermissaoSistema} from "@/stores/enums/EPermissaoSistema";
import AppLayout from "@/layout/AppLayout.vue";
import {useHomeStore} from "@/stores/home.store";


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/signin",
            name: "signin",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageSignin.vue')
        },
        {
            path: "/signin/admin",
            name: "signinadmin",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageSigninAdmin.vue')
        },
        {
            path: "/usuario/recuperacao-senha/:solicitacaoId",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageRecuperacaoSenha.vue')
        },
        {
            path: '/',
            component: AppLayout,
            meta: {authRequired: true, clientRequired: true},
            children: [
                {
                    path: '/',
                    name: 'home',
                    meta: {authRequired: true, clientRequired: true},
                    component: () => import('@/views/pages/home/PageHome.vue')
                },
                {
                    path: '/perfil',
                    name: 'perfil_cliente',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/perfil/PagePerfil.vue')
                },

                {
                    path: '/minha-empresa',
                    name: 'minhaEmpresa',
                    meta: {authRequired: true, clientRequired: true},
                    component: () => import('@/views/pages/empresa/PageEmpresasUsuarioCliente.vue')

                },
                {
                    path: '/conta-conveio',
                    name: 'conta-convenio',
                    meta: {authRequired: true, clientRequired: true},
                    component: () => import('@/views/pages/contaConvenio/PageContaConvenioUsuarioCliente.vue')

                },
                {
                    path: '/boletos',
                    name: 'boletos',
                    meta: {authRequired: true, clientRequired: true},
                    component: () => import('@/views/pages/boleto/PageBoleto.vue')

                },
                {
                    path: '/arquivo-retorno',
                    name: 'arquivoRetorno',
                    meta: {authRequired: true, clientRequired: true},
                    component: () => import('@/views/pages/arquivoRetorno/PageArquivoRetorno.vue')

                },

                {
                    path: '/permissao_negada',
                    name: 'permissao_negada',
                    component: () => import("@/layout/layoutPages/PagePermissaoNegada.vue")

                },


            ]
        },

        {
            path: '/admin',
            component: AppLayout,
            meta: {authRequired: true, internRequired: true},
            children: [
                {
                    path: '/admin',
                    name: 'homeAdmin',
                    meta: {authRequired: true, internRequired: true},
                    component: () => import('@/views/pages/home/PageHomeAdmin.vue')
                },
                {
                    path: '/perfil',
                    name: 'perfil_admin',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/perfil/PagePerfil.vue')
                },

                {
                    path: '/empresas',
                    name: 'empresas',
                    meta: {authRequired: true, internRequired: true},
                    component: () => import('@/views/pages/empresa/PageEmpresasUsuarioInterno.vue')
                },
                {
                    path: '/usuarios',
                    name: 'usuarios',
                    meta: {authRequired: true, internRequired: true},
                    component: () => import('@/views/pages/usuarioInterno/PageUsuarioInterno.vue')

                },
                {
                    path: '/boletos',
                    name: 'boletosAdmin',
                    meta: {authRequired: true, internRequired: true},
                    component: () => null

                },

                {
                    path: '/permissao_negada',
                    name: 'permissao_negada',
                    component: () => import("@/layout/layoutPages/PagePermissaoNegada.vue")

                },


            ]
        },


    ]
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const authRequired = (!to.meta ? true : to.meta.authRequired);
    const internRequired = (!to.meta ? true : to.meta.internRequired);
    const clientRequired = (!to.meta ? true : to.meta.clientRequired);
    const loggedIn = authStore.isLoggedIn();
    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = true;

    //Verifica se é necessário autenticação para acessar a rota/pagina
    //Verifica se o usuário está logado
    if (authRequired && !loggedIn) {

        //Caso necessita de autenticacao e o perfil nao estiver logado, redireciona para pagina de signin

        next('/signin');

    } else {
        //Está logado

        //Se necessaita de autenticacao, consulta os dados novamente
        if (authRequired) {

            //Verifica se faz a busca das informacoes do usuario novamente

            if (! await atualizarToken()) {

                authStore.sair();

                if (authStore.userRole == "CLIENTE") {
                    next('/signin');
                } else if (authStore.userRole == "INTERNO") {
                    next('/signin/admin');
                } else {
                    next('/signin');
                }

            } else {


                // //Verifica se a rota necessita estar autenticado e role interno
                if (clientRequired && authStore.userRole != "CLIENTE") {
                    next('/admin');
                } else if (internRequired && authStore.userRole != "INTERNO") {
                    next('/signin');

                } else {
                    //Continua para rota solicitada
                    next();
                }
            }
        } else {
            next();
        }

    }
});

router.afterEach(() => {

    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = false;
})


async function atualizarToken() {
    const authStore = useAuthStore();

    if (!authStore.userValueUpdated) {
        return await authStore.atualizarToken();
    } else {
        return true;
    }
}


export default router;
