import type {EmpresaVm} from "@/type/empresa.type";

export interface UsuarioInterno {
    publicId: string;
    nomeCompleto: string;
    apelido: string;
    cpf: string;
    celular: string;
    imagem: string;
    email: string;
    statusUsuario: EStatusUsuario;
    statusUsuarioDescricao: string;
}

export interface UsuarioInternoVm {
    publicId: string;
    nomeCompleto: string;
    apelido: string;
    cpf: string;
    celular: string;
    imagem: string;
    email: string;
    statusUsuario: EStatusUsuario;
    statusDescricao: string;
}

export interface UsuarioClienteVm{
    publicId: string;
    nomeCompleto: string;
    apelido: string;
    cpf: string;
    celular: string;
    imagem: string;
    email: string;
    status: number;
    statusDescricao: string;
    empresas: EmpresaVm[];
    empresaAutenticada: EmpresaVm;
}

export interface UsuarioVm{
    publicId: string;
    nomeCompleto: string;
    apelido: string;
    cpf: string;
    celular: string;
    imagem: string;
    email: string;
    status: number;
    statusDescricao: string;
    empresas: EmpresaVm[];
    empresaAutenticada: EmpresaVm;
}

export function dataAsUsuarioInternoVm(data: any) {
    return data as UsuarioInternoVm;
}
export function dataAsUsuarioClienteVm(data: any) {
    return data as UsuarioClienteVm;
}

export function dataAsUsuarioVm(data: any) {
    return data as UsuarioVm;
}

export enum EStatusUsuario {
    Cadastrado = 1,
    Confirmado = 2
}