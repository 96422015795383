import {type Ref, ref} from "vue";
import {defineStore} from "pinia";

import AuthService from "@/services/auth.service";
import AuthClienteService from "@/services/auth-cliente.service";
import {EStatusUsuario} from "@/type/usuario.type";


const userStorage = localStorage.getItem('user');
const userNameStorage = localStorage.getItem('userName');
const userRoleStorage = localStorage.getItem('userRole');
const userEmailStorage = localStorage.getItem("userEmail");
const userStatusStorage = localStorage.getItem("userStatus");
const userImageStore = localStorage.getItem('userImage');
const userPublicIdStorage = localStorage.getItem('userPublicId');


export const useAuthStore = defineStore('authStore', () => {


    const user = ref(userStorage);
    const userName = ref(userNameStorage);
    const userRole = ref(userRoleStorage);
    const userEmail = ref(userEmailStorage);
    const userStatus: Ref<string | EStatusUsuario | null> = ref(userStatusStorage);
    const userImage = ref(userImageStore);
    const userPublicId = ref(userPublicIdStorage);

    const userValueUpdated = ref(false);

    const usuarioPermissoes = ref();



    function isLoggedIn() {
        return !!localStorage.getItem('user');
    }

    function entrar(user: any, isAdmin = true) {

        if (isAdmin) {
            return AuthService
                .login(user)
                .then((userRet: any) => {

                    userName.value = userRet.nome;
                    userRole.value = userRet.role;
                    userEmail.value = userRet.email;
                    userStatus.value = userRet.statusUsuario;
                    userImage.value = userRet.imagem;
                    userPublicId.value = userRet.publicId;

                    userValueUpdated.value = true;

                    return Promise.resolve(userRet);

                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        } else {

            return AuthClienteService
                .login(user)
                .then((userRet: any) => {

                    userName.value = userRet.nome;
                    userRole.value = userRet.role;
                    userEmail.value = userRet.email;
                    userStatus.value = userRet.statusUsuario;
                    userImage.value = userRet.imagem;
                    userPublicId.value = userRet.publicId;

                    return Promise.resolve(userRet);

                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        }


    }
    function sair() {
        AuthService.logout();
        usuarioPermissoes.value = null;
    }

    async function atualizarToken(){

        try{

            const ret = await AuthService.atualizarToken();

            userValueUpdated.value = true;

            return true;

        }catch{
            return false;
        }


    }

    function setUserName(apelido: string) {
        userName.value = apelido;
        localStorage.setItem('userName', apelido);
    }

    function setUserStatus(statusUsuario: EStatusUsuario) {
        userStatus.value = statusUsuario;
        localStorage.setItem('userStatus', statusUsuario.toString());
    }

    function setUserEmail(email: string) {
        userEmail.value = email;
        localStorage.setItem('userEmail', email);
    }


    return {
        user,
        userName,
        userRole,
        userEmail,
        userStatus,
        userImage,
        userValueUpdated,
        isLoggedIn,
        userPublicId,
        entrar,
        sair,
        setUserName,
        setUserStatus,
        setUserEmail,
        atualizarToken,
    }
});



