<script setup lang="ts">
import {computed, ref} from 'vue';

import AppMenuItem from './AppMenuItem.vue';
import {useAuthStore} from "@/stores/auth.store";

const authStore = useAuthStore();

const model: any = computed(() => {

  const menuCliente = [
    {
      label: 'Home',
      items: [
        {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
        {
          label: "Boletos", icon: "pi pi-fw pi-file", to: {name: 'boletos'}
        },
        {
          label: "Arquivo Retorno", icon: "pi pi-fw pi-file-export", to: {name: 'arquivoRetorno'}
        },]
    },

    {
      label: 'Menu',
      items: [
        {
          label: "Minha Empresa", icon: "pi pi-fw pi-building", to: {name: 'minhaEmpresa'}
        },
        {
          label: "Conta/Convênio", icon: "pi pi-fw pi-building-columns", to: {name: 'conta-convenio'}
        },

      ]
    }
  ];

  const menuAdmin = [
    {
      label: 'Home',
      items: [
        {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
        {
          label: "Boletos", icon: "pi pi-fw pi-building", to: {name: 'boletos'}
        },]
    },

    {
      label: 'Menu',
      items: [
        {
          label: "Empresas", icon: "pi pi-fw pi-building", to: {name: 'empresas'}
        },
        {
          label: "Usuários", icon: "pi pi-fw pi pi-user", to: {name: 'usuarios'}
        },

      ]
    }
  ];


  return authStore.userRole == "INTERNO" ? menuAdmin : menuCliente;

});


</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
